import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Table from '../Table';
import TableHead from '../TableHead';
import TableBody from '../TableBody';
import TableRow from '../TableRow';
import TableCell from '../TableCell';

const StudyListExpandedRow = ({ seriesTableColumns, seriesTableDataSource, children }) => {
  console.log('SeriesTableDAtasource console', seriesTableDataSource);
  const { t } = useTranslation('StudyList');

  const [studyInstance, setStudyInstance] = useState();

  // const { accessToken } = useGetDeptFromStore();

  // const history = useHistory();

  const studyId = 'efe0e73b-f278-4adc-83b2-3908a6a7155d';

  const apiUrl = `https://solaceimaging-api-stg.herokuapp.com/api/study/${studyId}/viewer`;
  const accessToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlmZWFueWlvbHVjaGk3QGdtYWlsLmNvbSIsInR5cGUiOiJvcmciLCJvcmdJZD==6ImU3MmU0ODlhLTQ4N2YtNDJkNy04ZjBmLWNlMjI3ODhmZGM4MCIsIm9yZ05hbWUiOiJQZWFjZSBIZWFsdGgiLCJpYXQiOjE2OTc2MDA4MDQsImV4cCI6MTY5NzY4NzIwNH0.bjxWHEmWdZ_ygPKIhSDzBtRod4VLtwgMl9PNsnCqP7E';

  fetch(apiUrl, {
    method: 'GET',
    headers: {
      'x-auth-token': accessToken,
    },
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('The user data', data);

      const studyInstanceUID = data.data;
      setStudyInstance(data.data[0].StudyInstanceUID);
      console.log('This is the studyINstanceUID', studyInstanceUID);

      // history.push(`/viewer?StudyInstanceUIDs=${studyInstanceUID}`);
    })
    .catch(error => {
      console.error('Request error:', error);
    });

  return (
    <div className="w-full bg-black py-4 pl-12 pr-2">
      <div className="block">{children}</div>
      <div className="mt-4">
        <Table>
          <h2>I am trying out something {studyInstance}</h2>
          <TableHead>
            <TableRow>
              {Object.keys(seriesTableColumns).map(columnKey => {
                return <TableCell key={columnKey}>{t(seriesTableColumns[columnKey])}</TableCell>;
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {seriesTableDataSource.map((row, i) => (
              <TableRow key={i}>
                {Object.keys(row).map(cellKey => {
                  const content = row[cellKey];
                  return (
                    <TableCell
                      key={cellKey}
                      className="truncate"
                    >
                      {content}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
            {/* <h2>This is the studyInstance fetched {studyInstance}</h2> */}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

StudyListExpandedRow.propTypes = {
  seriesTableDataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
  seriesTableColumns: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default StudyListExpandedRow;
